import { graphql } from "gatsby"
import React from "react"
import CharityCards from "../components/CharityCards"
import CharityList from "../components/CharityList";
import Hero from "../components/Hero"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const CharitiesPage = ({ data }) => {


  return (
    <Layout>
      <SiteMetadata title="Charities" description="Ruald Strydom" />

      <div className="bg-gray-100 py-12 lg:py-16">
        <div className="container py-1 lg:pb-1">
          <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Charities Involved
          </h1>
        </div>
        {data.charities && data.charities.nodes.filter((node) => node.category === "Charities Involved").length > 0 ? (
          <CharityCards items={data.charities.nodes.filter((node) => node.category === "Charities Involved")} />
        ) : (
          <div className="container">No charities found.</div>
        )}
      </div>

      {data.charities && data.charities.nodes.filter((node) => node.category === "Donating Charities").length > 0 ? (
        <>
          <div className="bg-gray-100 py-12 lg:py-16">
            <div className="container py-1 lg:pb-1">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Donating Charities <sup>{" *"}</sup>
              </h1>
            </div>
            <CharityCards items={data.charities.nodes.filter((node) => node.category === "Donating Charities")} />
            <div className="container py-1 lg:pb-1">
              <p className="leading-tight tracking-tight text-gray-700">
                <sup>{"* "}</sup>Trademark Disclaimer - All trademarks, logos and brand names are the property of their respective owners. All company, NPO's (non-profit organizations), service names used in this website are for identification purposes only. Use of these names, trademarks, and brands does not imply endorsement or direct involvement in any way.
              </p>
            </div>
          </div>
        </>
      ) : undefined}
    </Layout>
  )
}

export default CharitiesPage

export const query = graphql`
      query CharitiesQuery {
        charities: allContentfulCharity(sort: {fields: name, order: ASC }) {
        nodes {
        ...CharityCard
      }
    }
  }
      `
