import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from "../components/Button"

const CharityCard = props => {
  const { name, slug, summary, thumbnail, category, url, emojis } = props

  return (
    <div className="bg-white h-full shadow-sm rounded-md overflow-hidden group">
      <Link to={category === "Charities Involved" ? `/${slug}` : url} {...(category === "Charities Involved" ? {} : { target: "_blank" })}>
        <div className="flex-auto text-right mx-5 mt-2">
          <span role="img">
            {emojis}
          </span>
        </div>
        <div className="group-hover:opacity-75 transition duration-150 ease-in-out">
          <Img fluid={thumbnail.localFile.childImageSharp.fluid} alt={name} />
        </div>
        <div className="p-4 sm:p-5">
          <h1 className="sm:text-lg text-gray-900 font-semibold">{name} </h1>
          <p className="text-sm sm:text-base text-gray-700">{summary}</p>

        </div>

      </Link>
    </div>
  )
}

CharityCard.propTypes = {
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  thumbnail: PropTypes.shape({
    localFile: PropTypes.object,
  }),
  category: PropTypes.string.isRequired,
}

export default CharityCard

export const query = graphql`
  fragment CharityCard on ContentfulCharity {
    id
    name
    slug
    thumbnail {
      localFile {
        childImageSharp {
          fluid(maxWidth: 444, maxHeight: 342, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    summary
    emojis
    category
    url
  }
`
